$("body").on(
  "animationend webkitAnimationEnd oAnimationEnd",
  ".nav-link .progress",
  function () {
    const tabs = $("#tabs-app > .nav-link");
    const active = tabs.filter(".active");
    const next = active.next(".nav-link:not(.active)");
    const toClick = next.length ? next : tabs.first();
    toClick.trigger("click");
  }
);

let descriptions = document.querySelectorAll("#tabs-app .description");
descriptions.forEach((element, i) => {
  if (i != 0) {
    collapse(element);
  }
});

let nav_tabs = document.querySelectorAll("#tabs-app .nav-link");
nav_tabs.forEach((tab) => {
  tab.addEventListener("show.bs.tab", function (event) {
    //   event.target; // newly activated tab
    //   event.relatedTarget; // previous active tab

    collapse(event.relatedTarget.querySelector(".description"));
    expand(event.target.querySelector(".description"));
  });
});

function collapse(element) {
  // get the height of the element's inner content, regardless of its actual size
  var sectionHeight = element.scrollHeight;

  // temporarily disable all css transitions
  var elementTransition = element.style.transition;
  element.style.transition = "";

  // on the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(function () {
    element.style.height = sectionHeight + "px";
    element.style.transition = elementTransition;

    // on the next frame (as soon as the previous style change has taken effect),
    // have the element transition to height: 0
    requestAnimationFrame(function () {
      element.style.height = 0 + "px";
    });
  });
}

function expand(element) {
  // get the height of the element's inner content, regardless of its actual size
  var sectionHeight = element.scrollHeight;

  // have the element transition to the height of its inner content
  element.style.height = sectionHeight + "px";

  // when the next css transition finishes (which should be the one we just triggered)
  element.addEventListener("transitionend", function (e) {
    // remove this event listener so it only gets triggered once
    element.removeEventListener("transitionend", arguments.callee);

    // remove "height" from the element's inline styles, so it can return to its initial value
    element.style.height = null;
  });
}

//mobile stuff
let swiper;
let swiperScelta;

//  swiperScelta.destroy();
//  $(".swiper-scelta .swiper-wrapper").addClass("row");
$("body").on(
  "animationend webkitAnimationEnd oAnimationEnd",
  ".swiper-app-container .swiper-pagination-bullet",
  () => swiper.slideNext()
);
$("body").on(
  "animationend webkitAnimationEnd oAnimationEnd",
  ".swiper-scelta .swiper-pagination-bullet",
  () => swiperScelta.slideNext()
);
let smallDevice = window.innerWidth >= 992 ? false : true;
window.onresize = function () {
  if (window.innerWidth >= 992) {
    smallDevice = false;
    $("#tabs-app .nav-link").removeClass("disabled");
    $("#tabs-app").height("auto");
    if (swiper != undefined) {
      swiper.destroy();
      swiper = undefined;

      $("#tabs-app .nav-link:not(.active) .description").height(0);
      const active = $("#tabs-app .nav-link.active .description");
      active.css("transition", "none");
      active.height("auto");
      active.css("transition", "all 0.3s linear");
    }
    if (swiperScelta != undefined) {
      swiperScelta.destroy();
      swiperScelta = undefined;
      $(".swiper-scelta .swiper-wrapper").addClass("row");
    }
    if (fullPage != undefined && !isFullPageDesktop) {
      $(".specs-mobile").removeClass("section");
      $(".specs-mobile").addClass("hidden");
      $(".specs-desktop").removeClass("hidden");
      $(".specs-desktop").addClass("section");
      fullpage_api.destroy("all");
      initFullpageJs();
    }
  } else {
    smallDevice = true;
    setNavPillsSize();
    createSlider();
    $("#tabs-app .nav-link").addClass("disabled");
    //nebbiogeno

    if (fullPage != undefined && isFullPageDesktop) {
      $(".specs-mobile").removeClass("hidden");
      $(".specs-mobile").addClass("section");
      $(".specs-desktop").removeClass("section");
      $(".specs-desktop").addClass("hidden");
      fullpage_api.destroy("all");
      initFullpageJs();
    }
  }
};

if (smallDevice) {
  setNavPillsSize();
  createSlider();
  $("#tabs-app .nav-link").addClass("disabled");
  //nebbiogeno
  $(".specs-mobile").removeClass("hidden");
  $(".specs-mobile").addClass("section");
  $(".specs-desktop").removeClass("section");
  $(".specs-desktop").addClass("hidden");
}

function setNavPillsSize() {
  let maxHeight = 0;
  $(".nav-link").each(function () {
    const value = $(this).outerHeight();
    maxHeight = value > maxHeight ? value : maxHeight;
  });
  $("#tabs-app").height(maxHeight);
  $(".swiper-pagination").css("bottom", -30 - maxHeight);

  createSlider();
}

function createSlider() {
  if (document.body.contains(document.querySelector(".header-sticky"))) {
    if (swiper == undefined) {
      swiper = new Swiper(".swiper", {
        direction: "horizontal",
        loop: true,
        allowTouchMove: true,
        pagination: {
          el: ".swiper-pagination",
        },
      });

      addEventsToSlider();
    }

    if (swiperScelta == undefined) {
      swiperScelta = new Swiper(".swiper-scelta", {
        direction: "horizontal",
        loop: true,
        allowTouchMove: true,
        pagination: {
          el: ".swiper-pagination-scelta",
        },
      });
      $(".swiper-scelta .swiper-wrapper").removeClass("row");
    }
  }
}
let fullPage;
let isFullPageDesktop = false;

function initFullpageJs() {
  if (document.body.contains(document.querySelector("#fullpage"))) {
    if (smallDevice) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      // We listen to the resize event
      window.addEventListener("resize", () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
      cssScrollSnapPolyfill();

      $("#donna").height($(".section").height());

      intersectionObservers();

      isFullPageDesktop = false;
    } else {
      lottie.loadAnimation({
        container: document.querySelector(".lottie-container"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "videos/nebbia.json",
      });

      $(".scopri-purifog").removeClass("fp-auto-height");
      fullPage = new fullpage("#fullpage", {
        licenseKey: "AE06FD19-9A2C4C20-A0B64FAF-178D3B62",
        anchors: [
          "page1",
          "page2",
          "page3",
          "page4",
          "page5",
          "page6",
          "page7",
        ],
        css3: true,
        scrollingSpeed: 750,
        controlArrows: false,
        scrollBar: true,
        lockAnchors: true,
        normalScrollElements: ".modal-open .modal",
        onLeave: function (origin, destination, direction) {
          if (this.anchor === "page2" && direction === "down") {
            $(".fullpage-wrapper #botique-bg").css("transform", "scale(1)");
            $(".fullpage-wrapper .overlay").css(
              "background-color",
              "transparent"
            );
            $(".nebbia").addClass("translate");
            $("#white-bg").addClass("active");
          } else if (this.anchor === "page3" && direction === "up") {
            $(".fullpage-wrapper .overlay").css("background-color", "black");
          } else if (this.anchor === "page4" && direction === "down") {
            $("#cards").addClass("animate");
          } else if (destination.anchor == "page7" && direction == "down") {
            setTimeout(() => {
              fullpage_api.setAutoScrolling(false);
              fullpage_api.setFitToSection(false);
            }, 1000);
          } else if (destination.anchor == "page6" && direction == "up") {
            fullpage_api.fitToSection();
          }
        },
        afterLoad: function (origin, destination, direction) {
          jQuery(".section.active [data-aos]").addClass("aos-animate");
          if (destination.anchor == "page6" && direction == "up") {
            setTimeout(() => {
              fullpage_api.setFitToSection(true);
              fullpage_api.setAutoScrolling(true);
            }, 1000);
          }
        },
      });
      isFullPageDesktop = true;
    }
  }
}

initFullpageJs();

function addEventsToSlider() {
  swiper.on("slideNextTransitionStart", function () {
    const tabs = $("#tabs-app > .nav-link");
    const active = tabs.filter(".active");
    const getNext = active.next(".nav-link:not(.active)");
    const next = getNext.length ? getNext : tabs.first();

    active.removeClass("active");
    next.addClass("active");
  });
  swiper.on("slidePrevTransitionStart", function () {
    const tabs = $("#tabs-app > .nav-link");
    const active = tabs.filter(".active");
    const getNext = active.prev(".nav-link:not(.active)");
    const next = getNext.length ? getNext : tabs.last();

    active.removeClass("active");
    next.addClass("active");
  });
}
$(window).on("scroll", function (e) {
  var $el = $(".header-sticky");
  if (document.body.contains(document.querySelector(".header-sticky"))) {
    var isPositionFixed = $el.hasClass("fixed");
    if ($(this).scrollTop() > 80 && !isPositionFixed) {
      if (!smallDevice) {
        $el.addClass("fixed");
        $("body").addClass("pt-7");
      } else {
      }
    }
    if ($(this).scrollTop() < 80 && isPositionFixed) {
      if (!smallDevice) {
        $el.removeClass("fixed");
      }
      $("body").removeClass("pt-7");
    }
  }
});

// Let the document know when the mouse is being used
document.body.addEventListener("mousedown", function () {
  document.body.classList.add("using-mouse");
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener("keydown", function (event) {
  if (event.key === "Tab") {
    document.body.classList.remove("using-mouse");
  }
});

document.addEventListener("DOMContentLoaded", function () {
  var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

  if ("IntersectionObserver" in window) {
    var lazyVideoObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            var videoSource = video.target.children[source];
            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
});

AOS.init();

let snap = true;
function intersectionObservers() {
  const comeFunzionaInView = new IntersectionObserver(
    (entries) => {
      if (entries[0].intersectionRatio > 0) {
        $(".fullpage-wrapper #botique-bg").css("transform", "scale(1)");
        $(".fullpage-wrapper .overlay").css("background-color", "transparent");
        $(".nebbia").addClass("translate");
        $("#white-bg").addClass("active");
      }
    },
    { threshold: 0.5 }
  );

  comeFunzionaInView.observe(document.getElementById("white-bg"));

  const scoprireInView = new IntersectionObserver(
    (entries) => {
      if (entries[0].intersectionRatio > 0) {
        $(".fullpage-wrapper .overlay").css("background-color", "black");
      }
    },
    { threshold: 1.0 }
  );

  scoprireInView.observe(document.getElementById("titolo-scoprire"));

  const logoFooterInView = new IntersectionObserver((entries) => {
    if (entries[0].intersectionRatio > 0) {
      $(".bg-container").addClass("hidden");
    } else {
      $(".bg-container").removeClass("hidden");
    }
  });

  logoFooterInView.observe(document.getElementById("logo-footer"));

  document.addEventListener(
    "scroll",
    () => {
      var target = document.getElementById("p-purifog");
      if (target.getBoundingClientRect().top <= 0) {
        if (snap) {
          $("#fullpage").removeClass("snapping");
          snap = false;
          console.log("no snap");
        }
      } else {
        if (!snap) {
          $("#fullpage").addClass("snapping");
          snap = true;
          console.log("snap");
        }
      }
    },
    true
  );
}
